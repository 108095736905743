<template>
  <b-modal
    id="modalCreateEditAluno"
    size="xl"
    :title="textModal"
    title-class="font-18"
    hide-footer
    no-close-on-backdrop
    @hidden="$emit('closeModal', false)"
  >
    <form @submit.prevent="save">
      <div class="col-lg-12">
        <b-tabs
          justified
          nav-class="nav-tabs-custom"
          content-class="p-3 text-muted"
        >
          <b-tab active>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="fas fa-home"></i>
              </span>
              <span class="d-none d-sm-inline-block">Dados do aluno(a)</span>
            </template>
            <div class="form-row">
              <div class="form-group col-sm-8">
                <label>Nome: <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': $v.aluno.name.$error }"
                  placeholder="Nome do aluno(a)"
                  v-model="$v.aluno.name.$model"
                />
                <span v-if="!$v.aluno.name.required" class="invalid-feedback">
                  O campo é obrigatório
                </span>
                <span v-if="!$v.aluno.name.minLength" class="invalid-feedback">
                  O nome do aluno deve possuir pelo menos 3 caracteres
                </span>
              </div>
              <div class="form-group col-sm-4">
                <label>Data de Nascimento:</label>
                <input
                  type="date"
                  class="form-control"
                  placeholder="Data de nascimento do aluno"
                  :class="{ 'is-invalid': $v.aluno.data_nascimento.$error }"
                  v-model="$v.aluno.data_nascimento.$model"
                />
                <span
                  v-if="!$v.aluno.data_nascimento.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-sm-6">
                <label>Turma:</label>
                <select
                  class="form-control"
                  :class="{ 'is-invalid': $v.aluno.turmas_id.$error }"
                  v-model="$v.aluno.turmas_id.$model"
                >
                  <option value="">Selecione uma turma</option>
                  <option
                    v-for="turma in turmas"
                    :key="turma.id"
                    :value="turma.id"
                    :title="`${turma.descricao}`"
                  >
                    {{ turma.descricao }}
                  </option>
                </select>
                <span
                  v-if="!$v.aluno.turmas_id.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="form-group col-sm-6">
                <label>Turno:</label>
                <select
                  class="form-control"
                  :class="{ 'is-invalid': $v.aluno.turno.$error }"
                  v-model="$v.aluno.turno.$model"
                >
                  <option value="">Selecione o turno do aluno</option>
                  <option value="1">Integral</option>
                  <option value="2">Diurno</option>
                  <option value="3">Vespertino</option>
                </select>
                <span v-if="!$v.aluno.turno.required" class="invalid-feedback">
                  O campo é obrigatório
                </span>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-6">
                <label>Contrato:</label>
                <select
                  class="form-control"
                  :class="{ 'is-invalid': $v.aluno.contrato_id.$error }"
                  v-model="$v.aluno.contrato_id.$model"
                >
                  <option value="">Selecione um contrato</option>
                  <option
                    v-for="contrato in contratos"
                    :key="contrato.id"
                    :value="contrato.id"
                    :title="`${contrato.titulo} - ${contrato.name}`"
                  >
                    {{ contrato.titulo }} - {{ contrato.name }}
                  </option>
                </select>
                <span
                  v-if="!$v.aluno.contrato_id.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="form-group col-sm-6">
                <label>observação: <span class="text-danger">*</span></label>
                <textarea
                  type="text"
                  class="form-control"
                  placeholder="Observações do aluno"
                  v-model="aluno.observacao"
                />
              </div>
            </div>
            <div class="form-row">
              <div v-if="alunoId" class="form-group col-sm-6">
                <p>Status: <span class="text-danger">*</span></p>
                <div class="form-check form-check-inline">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="statusInativo"
                    value="0"
                    v-model="aluno.status"
                  />
                  <label class="form-check-label" for="statusInativo">
                    Inativo
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="statusAtivo"
                    value="1"
                    v-model="aluno.status"
                  />
                  <label class="form-check-label" for="statusAtivo">
                    Ativo
                  </label>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="fas fa-home"></i>
              </span>
              <span class="d-none d-sm-inline-block">Responsáveis</span>
            </template>
            <label class="text-bold">Responsável 1</label>
            <hr />
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label
                  >Nome do responsável:
                  <span class="text-danger">*</span></label
                >
                <input
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': $v.aluno.nome_responsavel1.$error }"
                  placeholder="Nome do responsável"
                  v-model="$v.aluno.nome_responsavel1.$model"
                />
                <span
                  v-if="!$v.aluno.nome_responsavel1.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
                <span
                  v-if="!$v.aluno.nome_responsavel1.minLength"
                  class="invalid-feedback"
                >
                  O nome do responsável deve possuir pelo menos 3 caracteres
                </span>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-6">
                <label>CPF: </label>
                <the-mask
                  type="text"
                  class="form-control"
                  v-model="aluno.cpf_responsavel1"
                  :mask="['###.###.###-##']"
                  :masked="true"
                  placeholder="000.000.000-00"
                />
              </div>
              <div class="form-group col-sm-6">
                <label>RG:</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="RG do responsável"
                  v-model="aluno.rg_responsavel1"
                />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-6">
                <label>E-mail do responsável: </label>
                <input
                  type="email"
                  class="form-control"
                  :class="{ 'is-invalid': $v.aluno.email_responsavel1.$error }"
                  v-model="$v.aluno.email_responsavel1.$model"
                  placeholder="usuario@email.com.br"
                />
                <span
                  v-if="!$v.aluno.email_responsavel1.email"
                  class="invalid-feedback"
                >
                  Formato de e-mail inválido
                </span>
              </div>
              <div class="form-group col-sm-6">
                <label>Telefone:</label>
                <the-mask
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': $v.aluno.telefone_responsavel1.$error,
                  }"
                  placeholder="(00) 98888-8888"
                  v-model="$v.aluno.telefone_responsavel1.$model"
                  :mask="['(##) ####-####', '(##) #####-####']"
                  :masked="true"
                />
                <span
                  v-if="!$v.aluno.telefone_responsavel1.minLength"
                  class="invalid-feedback"
                >
                  Formato de telefone inválido <br />
                  Ex: (00) 98888-8888 / (00) 8888-8888
                </span>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-4">
                <label>Profissão: </label>
                <input
                  type="text"
                  class="form-control"
                  v-model="aluno.profissao_responsavel1"
                  placeholder="Profissão do responsável 1"
                />
              </div>
              <div class="form-group col-sm-4">
                <label>Local de Trabalho:</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Local de Trabalho do responsável 1"
                  v-model="aluno.local_trabalho_responsavel1"
                />
              </div>
              <div class="form-group col-sm-4">
                <label>Telefone de trabalho:</label>
                <the-mask
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid':
                      $v.aluno.telefone_trabalho_responsavel1.$error,
                  }"
                  placeholder="(00) 98888-8888"
                  v-model="$v.aluno.telefone_trabalho_responsavel1.$model"
                  :mask="['(##) ####-####', '(##) #####-####']"
                  :masked="true"
                />
                <span
                  v-if="!$v.aluno.telefone_trabalho_responsavel1.minLength"
                  class="invalid-feedback"
                >
                  Formato de telefone inválido <br />
                  Ex: (00) 98888-8888 / (00) 8888-8888
                </span>
              </div>
            </div>
            <br />
            <label class="text-bold">Responsável 2</label>
            <hr />
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>Nome do responsável: </label>
                <input
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': $v.aluno.nome_responsavel2.$error }"
                  placeholder="Nome do responsável"
                  v-model="$v.aluno.nome_responsavel2.$model"
                />
                <span
                  v-if="!$v.aluno.nome_responsavel2.minLength"
                  class="invalid-feedback"
                >
                  O nome do responsável deve possuir pelo menos 3 caracteres
                </span>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-6">
                <label>CPF: </label>
                <the-mask
                  type="text"
                  class="form-control"
                  v-model="aluno.cpf_responsavel2"
                  :mask="['###.###.###-##']"
                  :masked="true"
                  placeholder="000.000.000-00"
                />
              </div>
              <div class="form-group col-sm-6">
                <label>RG:</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="RG do responsável"
                  v-model="aluno.rg_responsavel2"
                />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-6">
                <label>E-mail do responsável: </label>
                <input
                  type="email"
                  class="form-control"
                  :class="{ 'is-invalid': $v.aluno.email_responsavel2.$error }"
                  v-model="$v.aluno.email_responsavel2.$model"
                  placeholder="usuario@email.com.br"
                />
                <span
                  v-if="!$v.aluno.email_responsavel2.email"
                  class="invalid-feedback"
                >
                  Formato de e-mail inválido
                </span>
              </div>
              <div class="form-group col-sm-6">
                <label>Telefone:</label>
                <the-mask
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': $v.aluno.telefone_responsavel2.$error,
                  }"
                  placeholder="(00) 98888-8888"
                  v-model="$v.aluno.telefone_responsavel2.$model"
                  :mask="['(##) ####-####', '(##) #####-####']"
                  :masked="true"
                />
                <span
                  v-if="!$v.aluno.telefone_responsavel2.minLength"
                  class="invalid-feedback"
                >
                  Formato de telefone inválido <br />
                  Ex: (00) 98888-8888 / (00) 8888-8888
                </span>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-4">
                <label>Profissão: </label>
                <input
                  type="text"
                  class="form-control"
                  v-model="aluno.profissao_responsavel2"
                  placeholder="Profissão do responsável 2"
                />
              </div>
              <div class="form-group col-sm-4">
                <label>Local de Trabalho:</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Local de Trabalho do responsável 2"
                  v-model="aluno.local_trabalho_responsavel2"
                />
              </div>
              <div class="form-group col-sm-4">
                <label>Telefone de trabalho:</label>
                <the-mask
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid':
                      $v.aluno.telefone_trabalho_responsavel2.$error,
                  }"
                  placeholder="(00) 98888-8888"
                  v-model="$v.aluno.telefone_trabalho_responsavel2.$model"
                  :mask="['(##) ####-####', '(##) #####-####']"
                  :masked="true"
                />
                <span
                  v-if="!$v.aluno.telefone_trabalho_responsavel2.minLength"
                  class="invalid-feedback"
                >
                  Formato de telefone inválido <br />
                  Ex: (00) 98888-8888 / (00) 8888-8888
                </span>
              </div>
            </div>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="fas fa-home"></i>
              </span>
              <span class="d-none d-sm-inline-block">Ficha de saúde</span>
            </template>
            <div>
              <div class="form-row">
                <div class="form-group col-sm-6">
                  <p>1 - As vacinas estão em dia?</p>
                  <div class="form-check form-check-inline">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="vacinas_em_dia_nao"
                      value="0"
                      v-model="aluno.vacinas_em_dia"
                    />
                    <label class="form-check-label" for="vacinas_em_dia_nao">
                      Não
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="vacinas_em_dia_sim"
                      value="1"
                      v-model="aluno.vacinas_em_dia"
                    />
                    <label class="form-check-label" for="vacinas_em_dia_sim">
                      Sim
                    </label>
                  </div>
                </div>
                <div class="form-group col-sm-6">
                  <p>16 - É portador de necessidade especial?</p>
                  <div class="form-check form-check-inline">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="portador_necessidade_especial_nao"
                      value="0"
                      v-model="aluno.portador_necessidade_especial"
                    />
                    <label
                      class="form-check-label"
                      for="portador_necessidade_especial_nao"
                    >
                      Não
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="portador_necessidade_especial_sim"
                      value="1"
                      v-model="aluno.portador_necessidade_especial"
                    />
                    <label
                      class="form-check-label"
                      for="portador_necessidade_especial_sim"
                    >
                      Sim
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-sm-6">
                  <label>2 - Qual vacina ainda não tomou?</label>
                  <input
                    type="text"
                    class="form-control col-sm-10"
                    placeholder="Vacinas não tomadas"
                    v-model="aluno.vacina_nao_tomada"
                  />
                </div>
                <div class="form-group col-sm-6">
                  <label>17 - Qual necessidade especial?</label>
                  <input
                    type="text"
                    class="form-control col-sm-10"
                    placeholder="Se Sim, qual necessidade especial"
                    v-model="aluno.necessidade_especial"
                  />
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-sm-6">
                  <p>3 - Tem algum problema respiratório?</p>
                  <div class="form-check form-check-inline">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="problema_respiratorio_nao"
                      value="0"
                      v-model="aluno.problema_respiratorio"
                    />
                    <label
                      class="form-check-label"
                      for="problema_respiratorio_nao"
                    >
                      Não
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="problema_respiratorio_sim"
                      value="1"
                      v-model="aluno.problema_respiratorio"
                    />
                    <label
                      class="form-check-label"
                      for="problema_respiratorio_sim"
                    >
                      Sim
                    </label>
                  </div>
                </div>
                <div class="form-group col-sm-6">
                  <p>18 - Toma alguém medicamento de uso contínuo?</p>
                  <div class="form-check form-check-inline">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="medicamento_uso_continuo_nao"
                      value="0"
                      v-model="aluno.medicamento_uso_continuo"
                    />
                    <label
                      class="form-check-label"
                      for="medicamento_uso_continuo_nao"
                    >
                      Não
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="medicamento_uso_continuo_sim"
                      value="1"
                      v-model="aluno.medicamento_uso_continuo"
                    />
                    <label
                      class="form-check-label"
                      for="medicamento_uso_continuo_sim"
                    >
                      Sim
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-sm-6">
                  <label>4 - Se sim, qual doença respiratória?</label>
                  <input
                    type="text"
                    class="form-control col-sm-10"
                    placeholder="Descrevas as doenças respiratórias"
                    v-model="aluno.problema_respiratorio_qual"
                  />
                </div>
                <div class="form-group col-sm-6">
                  <label>19 - Se sim, qual medicamento e qual a dosagem?</label>
                  <input
                    type="text"
                    class="form-control col-sm-10"
                    placeholder="Nomes dos medicamentos e dosagens"
                    v-model="aluno.medicamento_qual"
                  />
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-sm-6">
                  <p>5 - Já teve convulsões?</p>
                  <div class="form-check form-check-inline">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="teve_convulsao_nao"
                      value="0"
                      v-model="aluno.teve_convulsao"
                    />
                    <label class="form-check-label" for="teve_convulsao_nao">
                      Não
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="teve_convulsao_sim"
                      value="1"
                      v-model="aluno.teve_convulsao"
                    />
                    <label class="form-check-label" for="teve_convulsao_sim">
                      Sim
                    </label>
                  </div>
                </div>
                <div class="form-group col-sm-6">
                  <p>20 - É alérgico(a)?</p>
                  <div class="form-check form-check-inline">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="alergico_nao"
                      value="0"
                      v-model="aluno.alergico"
                    />
                    <label class="form-check-label" for="alergico_nao">
                      Não
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="alergico_sim"
                      value="1"
                      v-model="aluno.alergico"
                    />
                    <label class="form-check-label" for="alergico_sim">
                      Sim
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-sm-6">
                  <label>6 - Se sim, por quê?</label>
                  <input
                    type="text"
                    class="form-control col-sm-10"
                    placeholder="Motivo da convulsão"
                    v-model="aluno.motivo_convulsao"
                  />
                </div>
                <div class="form-group col-sm-6">
                  <label>21 - Se sim, alergia a que?</label>
                  <input
                    type="text"
                    class="form-control col-sm-10"
                    placeholder="Tipos de alergias"
                    v-model="aluno.alergia_descricao"
                  />
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-sm-6">
                  <p>7 - Tem hipertensão?</p>
                  <div class="form-check form-check-inline">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="hipertensao_nao"
                      value="0"
                      v-model="aluno.hipertensao"
                    />
                    <label class="form-check-label" for="hipertensao_nao">
                      Não
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="hipertensao_sim"
                      value="1"
                      v-model="aluno.hipertensao"
                    />
                    <label class="form-check-label" for="hipertensao_sim">
                      Sim
                    </label>
                  </div>
                </div>
                <div class="form-group col-sm-6">
                  <p>22 - Tem asma?</p>
                  <div class="form-check form-check-inline">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="asma_nao"
                      value="0"
                      v-model="aluno.asma"
                    />
                    <label class="form-check-label" for="asma_nao"> Não </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="asma_sim"
                      value="1"
                      v-model="aluno.asma"
                    />
                    <label class="form-check-label" for="asma_sim"> Sim </label>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-sm-6">
                  <p>8 - Se sim, está em tratamento</p>
                  <div class="form-check form-check-inline">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="tratamento_hispertensao_nao"
                      value="0"
                      v-model="aluno.tratamento_hispertensao"
                    />
                    <label
                      class="form-check-label"
                      for="tratamento_hispertensao_nao"
                    >
                      Não
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="tratamento_hispertensao_sim"
                      value="1"
                      v-model="aluno.tratamento_hispertensao"
                    />
                    <label
                      class="form-check-label"
                      for="tratamento_hispertensao_sim"
                    >
                      Sim
                    </label>
                  </div>
                </div>
                <div class="form-group col-sm-6">
                  <p>23 - Tem bronquite?</p>
                  <div class="form-check form-check-inline">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="bronquite_nao"
                      value="0"
                      v-model="aluno.bronquite"
                    />
                    <label class="form-check-label" for="bronquite_nao">
                      Não
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="bronquite_sim"
                      value="1"
                      v-model="aluno.bronquite"
                    />
                    <label class="form-check-label" for="bronquite_sim">
                      Sim
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-sm-6">
                  <p>9 - É epilético?</p>
                  <div class="form-check form-check-inline">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="epilepsia_nao"
                      value="0"
                      v-model="aluno.epilepsia"
                    />
                    <label class="form-check-label" for="epilepsia_nao">
                      Não
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="epilepsia_sim"
                      value="1"
                      v-model="aluno.epilepsia"
                    />
                    <label class="form-check-label" for="epilepsia_sim">
                      Sim
                    </label>
                  </div>
                </div>
                <div class="form-group col-sm-6">
                  <p>24 - Tem diabetes?</p>
                  <div class="form-check form-check-inline">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="diabetes_nao"
                      value="0"
                      v-model="aluno.diabetes"
                    />
                    <label class="form-check-label" for="diabetes_nao">
                      Não
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="diabetes_sim"
                      value="1"
                      v-model="aluno.diabetes"
                    />
                    <label class="form-check-label" for="diabetes_sim">
                      Sim
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-sm-6">
                  <p>10 - Tem restrição alimentar?</p>
                  <div class="form-check form-check-inline">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="restricao_alimentar_nao"
                      value="0"
                      v-model="aluno.restricao_alimentar"
                    />
                    <label
                      class="form-check-label"
                      for="restricao_alimentar_nao"
                    >
                      Não
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="restricao_alimentar_sim"
                      value="1"
                      v-model="aluno.restricao_alimentar"
                    />
                    <label
                      class="form-check-label"
                      for="restricao_alimentar_sim"
                    >
                      Sim
                    </label>
                  </div>
                </div>
                <div class="form-group col-sm-6">
                  <p>25 - Possui restrição a alguma atividade física?</p>
                  <div class="form-check form-check-inline">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="restricao_atividade_fisica_nao"
                      value="0"
                      v-model="aluno.restricao_atividade_fisica"
                    />
                    <label
                      class="form-check-label"
                      for="restricao_atividade_fisica_nao"
                    >
                      Não
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="restricao_atividade_fisica_sim"
                      value="1"
                      v-model="aluno.restricao_atividade_fisica"
                    />
                    <label
                      class="form-check-label"
                      for="restricao_atividade_fisica_sim"
                    >
                      Sim
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-sm-6">
                  <label>11 - Se sim, quais alimentos?</label>
                  <input
                    type="text"
                    class="form-control col-sm-10"
                    placeholder="Descreva os alimentos"
                    v-model="aluno.restricao_alimentar_qual"
                  />
                </div>
                <div class="form-group col-sm-6">
                  <label>26 - Se sim, qual restrição física?</label>
                  <input
                    type="text"
                    class="form-control col-sm-10"
                    placeholder="Descreva qual restrição"
                    v-model="aluno.restricao_atividade_fisica_qual"
                  />
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-sm-6">
                  <p>
                    12 - Apresenta alguma alteração comportamental como: TOD,
                    TEA, TDAH, Síndrome de Down ou outra que exija
                    acompanhamento ou cuidados especiais?
                  </p>
                  <div class="form-check form-check-inline">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="alteracao_comportamental_nao"
                      value="0"
                      v-model="aluno.alteracao_comportamental"
                    />
                    <label
                      class="form-check-label"
                      for="alteracao_comportamental_nao"
                    >
                      Não
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="alteracao_comportamental_sim"
                      value="1"
                      v-model="aluno.alteracao_comportamental"
                    />
                    <label
                      class="form-check-label"
                      for="alteracao_comportamental_sim"
                    >
                      Sim
                    </label>
                  </div>
                </div>
                <div class="form-group col-sm-6">
                  <label>27 - Possui outras doenças?</label>
                  <input
                    type="text"
                    class="form-control col-sm-10"
                    placeholder="Quais doenças"
                    v-model="aluno.outras_doencas"
                  />
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-sm-6">
                  <label>13 - Se sim, qual?</label>
                  <input
                    type="text"
                    class="form-control col-sm-10"
                    placeholder="Qual tipo de alteração comportamental"
                    v-model="aluno.alteracao_comportamental_qual"
                  />
                </div>
                <div class="form-group col-sm-6">
                  <label
                    >28 - Em caso de febre alta, não sendo localizados os
                    responsáveis pelo aluno, qual medicamento deve ser
                    ministrado pelo serviço de socorro?</label
                  >
                  <input
                    type="text"
                    class="form-control col-sm-10"
                    placeholder="Descreva a medicação"
                    v-model="aluno.febre_alta"
                  />
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-sm-6">
                  <p>14 - Possui convênio médico?</p>
                  <div class="form-check form-check-inline">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="convenio_medico_nao"
                      value="0"
                      v-model="aluno.convenio_medico"
                    />
                    <label class="form-check-label" for="convenio_medico_nao">
                      Não
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="convenio_medico_sim"
                      value="1"
                      v-model="aluno.convenio_medico"
                    />
                    <label class="form-check-label" for="convenio_medico_sim">
                      Sim
                    </label>
                  </div>
                </div>
                <div class="form-group col-sm-6">
                  <label>29 - Qual tipo sanguíneo?</label>
                  <input
                    type="text"
                    class="form-control col-sm-10"
                    placeholder="Tipo sanguíneo"
                    v-model="aluno.tipo_sanguineo"
                  />
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-sm-6">
                  <label>15 - Se sim, qual convênio?</label>
                  <input
                    type="text"
                    class="form-control col-sm-10"
                    placeholder="Nome do convênio médico"
                    v-model="aluno.convenio_medico_qual"
                  />
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
      <hr />
      <div class="modal-footer-custom">
        <button
          type="button"
          class="btn btn-secondary"
          title="Cancelar"
          @click="$emit('closeModal', false)"
        >
          Cancelar
        </button>
        <button
          type="submit"
          class="btn btn-primary"
          :title="textModal"
          :disabled="$v.$invalid || disableButton"
        >
          <i
            v-if="disableButton"
            class="spinner-grow spinner-grow-custom text-wither"
          />
          <span v-else>{{ textModal }}</span>
        </button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { required, minLength, email } from 'vuelidate/lib/validators'
import { TheMask } from 'vue-the-mask'

export default {
  name: 'CreateEditAlunoModal',

  components: {
    TheMask,
  },

  props: {
    alunoId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      aluno: {
        name: '',
        data_nascimento: '',
        nome_responsavel1: '',
        telefone_responsavel1: '',
        email_responsavel1: '',
        cpf_responsavel1: '',
        rg_responsavel1: '',
        profissao_responsavel1: '',
        local_trabalho_responsavel1: '',
        telefone_trabalho_responsavel1: '',
        nome_responsavel2: '',
        telefone_responsavel2: '',
        email_responsavel2: '',
        cpf_responsavel2: '',
        rg_responsavel2: '',
        profissao_responsavel2: '',
        local_trabalho_responsavel2: '',
        telefone_trabalho_responsavel2: '',
        contrato_id: '',
        turno: '',
        turmas_id: '',
        vacinas_em_dia: '',
        vacina_nao_tomada: '',
        portador_necessidade_especial: '',
        necessidade_especial: '',
        problema_respiratorio: '',
        problema_respiratorio_qual: '',
        medicamento_uso_continuo: '',
        medicamento_qual: '',
        medicamento_dosagem: '',
        teve_convulsao: '',
        motivo_convulsao: '',
        alergico: '',
        alergia_descricao: '',
        hipertensao: '',
        tratamento_hispertensao: '',
        asma: '',
        bronquite: '',
        epilepsia: '',
        diabetes: '',
        restricao_alimentar: '',
        restricao_alimentar_qual: '',
        restricao_atividade_fisica: '',
        restricao_atividade_fisica_qual: '',
        alteracao_comportamental: '',
        alteracao_comportamental_qual: '',
        outras_doencas: '',
        convenio_medico: '',
        convenio_medico_qual: '',
        tipo_sanguineo: '',
        febre_alta: '',
        observacao: '',
      },
      contratos: '',
      turmas: '',
      disableButton: false,
    }
  },

  computed: {
    textModal() {
      return this.alunoId ? 'Atualizar' : 'Cadastrar'
    },
  },

  methods: {
    async getAluno() {
      try {
        this.aluno = await this.$store.dispatch('getAluno', this.alunoId)
        this.$bvModal.show('modalCreateEditAluno')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
        this.$emit('closeModal', false)
      }
    },

    async getTurmas() {
      try {
        this.turmas = await this.$store.dispatch('getTurmas')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getContratos() {
      try {
        this.contratos = await this.$store.dispatch('getContratosAll')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async save() {
      if (!this.$v.$invalid) {
        this.disableButton = true
        try {
          let response = null
          if (this.alunoId) {
            response = await this.$store.dispatch('updateAluno', {
              id: this.alunoId,
              aluno: this.aluno,
            })
          } else {
            response = await this.$store.dispatch('saveAluno', this.aluno)
          }
          this.disableButton = false
          this.$root.$bvToast.toast(response.message, {
            title: 'Sucesso!',
            variant: 'success',
            solid: true,
          })
          this.$emit('closeModal', true)
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$bvToast.toast(error.message, {
              title: 'Atenção!',
              variant: 'warning',
              solid: true,
            })
          } else {
            this.$root.$bvToast.toast(
              'Não foi possível se conectar ao servidor.',
              {
                title: 'Erro!',
                variant: 'danger',
                solid: true,
              },
            )
          }
        }
      }
    },
  },

  validations: {
    aluno: {
      name: {
        required,
        minLength: minLength(3),
      },
      nome_responsavel1: {
        required,
        minLength: minLength(3),
      },
      nome_responsavel2: {
        minLength: minLength(3),
      },
      telefone_responsavel1: {
        minLength: minLength(11),
      },
      telefone_responsavel2: {
        minLength: minLength(11),
      },
      telefone_trabalho_responsavel1: {
        minLength: minLength(11),
      },
      telefone_trabalho_responsavel2: {
        minLength: minLength(11),
      },
      email_responsavel1: { email },
      email_responsavel2: { email },
      data_nascimento: {
        required,
      },
      turmas_id: {
        required,
      },
      contrato_id: {
        required,
      },
      turno: {
        required,
      },
    },
  },

  mounted() {
    this.getContratos()
    this.getTurmas()
    if (this.alunoId) {
      this.getAluno()
    } else {
      this.$bvModal.show('modalCreateEditAluno')
    }
  },
}
</script>
