<template>
  <b-modal
    id="modalCreateEditUser"
    size="xl"
    :title="textModal"
    title-class="font-18"
    hide-footer
    no-close-on-backdrop
    @hidden="$emit('closeModal', false)"
  >
    <form @submit.prevent="save">
      <!-- NOME E DATA DENASCIMENTO -->
      <div class="form-row">
        <div class="form-group col-sm-6">
          <label>Nome: <span class="text-danger">*</span></label>
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.user.name.$error }"
            placeholder="Nome Completo"
            v-model="$v.user.name.$model"
          />
          <span v-if="!$v.user.name.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
          <span v-if="!$v.user.name.minLength" class="invalid-feedback">
            O nome deve possuir pelo menos 8 caracteres
          </span>
        </div>
        <div class="form-group col-sm-4">
          <label>Data de nascimento: <span class="text-danger">*</span></label>
          <input
            type="date"
            class="form-control"
            :class="{ 'is-invalid': $v.user.data_nascimento.$error }"
            placeholder="Data de nascimento"
            v-model="$v.user.data_nascimento.$model"
          />
          <span
            v-if="!$v.user.data_nascimento.required"
            class="invalid-feedback"
          >
            O campo é obrigatório
          </span>
        </div>
        <div class="form-group col-sm-2">
          <label>Sexo: <span class="text-danger">*</span></label>
          <select
            class="form-control"
            :class="{ 'is-invalid': $v.user.sexo.$error }"
            v-model="$v.user.sexo.$model"
          >
            <option value="">Selecione o sexo</option>
            <option value="F">Feminino</option>
            <option value="M">Masculino</option>
          </select>
          <span v-if="!$v.user.sexo.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
        </div>
      </div>
      <!-- CPF E RG -->
      <div class="form-row">
        <div class="form-group col-sm-4">
          <label>CPF: <span class="text-danger">*</span></label>
          <the-mask
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.user.cpf.$error }"
            placeholder="000.000.000-00"
            v-model="$v.user.cpf.$model"
            :mask="'###.###.###-##'"
            :masked="true"
          />
          <span v-if="!$v.user.cpf.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
        </div>
        <div class="form-group col-sm-4">
          <label>RG:</label>
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.user.rg.$error }"
            placeholder="Nº do RG"
            v-model="$v.user.rg.$model"
          />
        </div>
        <div class="form-group col-sm-4">
          <label>Órgão expeditor:</label>
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.user.rg_orgao_expeditor.$error }"
            placeholder="Órgão expeditor do RG"
            v-model="$v.user.rg_orgao_expeditor.$model"
          />
        </div>
      </div>

      <!-- EMAIL E CELULAR -->
      <div class="form-row">
        <div class="form-group col-sm-6">
          <label>E-mail: <span class="text-danger">*</span></label>
          <input
            type="email"
            class="form-control"
            :class="{ 'is-invalid': $v.user.email.$error }"
            v-model="$v.user.email.$model"
            placeholder="usuario@email.com.br"
          />
          <span v-if="!$v.user.email.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
          <span v-if="!$v.user.email.email" class="invalid-feedback">
            Formato de e-mail inválido
          </span>
        </div>
        <div class="form-group col-sm-6">
          <label>Telefone:</label>
          <the-mask
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.user.phone.$error }"
            placeholder="(00) 98888-8888"
            v-model="$v.user.phone.$model"
            :mask="['(##) ####-####', '(##) #####-####']"
            :masked="true"
          />
          <span v-if="!$v.user.phone.minLength" class="invalid-feedback">
            Formato de telefone inválido <br />
            Ex: (00) 98888-8888 / (00) 8888-8888
          </span>
        </div>
      </div>

      <!-- ESCOLARIDADE/PIS/PASEP -->
      <div class="form-row">
        <div class="form-group col-sm-6">
          <label>Escolaridade: <span class="text-danger">*</span></label>
          <select class="form-control" v-model="user.escolaridade">
            <option value="">Selecione um grau de escolaridade</option>
            <option value="1">Ensino Fundamental</option>
            <option value="2">Ensino Médio</option>
            <option value="3">Ensino Superior</option>
            <option value="4">Doutorado</option>
            <option value="5">Pós-Doutorado</option>
          </select>
        </div>
        <div class="form-group col-sm-6">
          <label>PIS/PASEP:</label>
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.user.pis_pasep.$error }"
            placeholder="Nº do PIS/PASEP"
            v-model="$v.user.pis_pasep.$model"
          />
        </div>
      </div>

      <!-- TÍTULO DE ELEITOR -->
      <div class="form-row">
        <div class="form-group col-sm-4">
          <label>Título de Eleitor:</label>
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.user.titulo_eleitor.$error }"
            placeholder="Nº do título de eleitor"
            v-model="$v.user.titulo_eleitor.$model"
          />
        </div>
        <div class="form-group col-sm-4">
          <label>Zona:</label>
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.user.zona.$error }"
            placeholder="Zona de votação"
            v-model="$v.user.zona.$model"
          />
        </div>
        <div class="form-group col-sm-4">
          <label>Seção:</label>
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.user.secao.$error }"
            placeholder="Seção de votação"
            v-model="$v.user.secao.$model"
          />
        </div>
      </div>

      <!-- ENDEREÇO -->
      <div class="form-row">
        <div class="form-group col-sm-8">
          <label>Endereço: <span class="text-danger">*</span></label>
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.user.endereco.$error }"
            placeholder="Rua, Av., Tv..."
            v-model="$v.user.endereco.$model"
          />
          <span v-if="!$v.user.endereco.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
        </div>
        <div class="form-group col-sm-4">
          <label>Nº: <span class="text-danger">*</span></label>
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.user.num_endereco.$error }"
            placeholder="0000"
            v-model="$v.user.num_endereco.$model"
          />
          <span v-if="!$v.user.num_endereco.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
        </div>
      </div>

      <!-- BAIRRO, ESTADO E CIDADE -->
      <div class="form-row">
        <div class="form-group col-sm-4">
          <label>Bairro:</label>
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.user.bairro.$error }"
            placeholder="Nome do bairro"
            v-model="$v.user.bairro.$model"
          />
        </div>
        <div class="form-group col-sm-4">
          <label>Estado: <span class="text-danger">*</span></label>
          <select
            class="form-control"
            :class="{ 'is-invalid': $v.user.city_state_id.$error }"
            v-model="$v.user.city_state_id.$model"
          >
            <option value="">Selecione o estado</option>
            <option
              v-for="state in states"
              :key="state.id"
              :value="state.id"
              :title="`${state.uf} - ${state.name}`"
            >
              {{ state.uf }}
            </option>
          </select>
          <span v-if="!$v.user.city_state_id.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
        </div>
        <div class="form-group col-sm-4">
          <label>Cidade: <span class="text-danger">*</span></label>
          <select
            class="form-control"
            :class="{ 'is-invalid': $v.user.city_id.$error }"
            v-model="$v.user.city_id.$model"
          >
            <option value="">Selecione uma cidade</option>
            <option
              v-for="city in cities"
              :key="city.id"
              :value="city.id"
              :title="city.name"
            >
              {{ city.name }}
            </option>
          </select>
          <span v-if="!$v.user.city_id.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
        </div>
      </div>

      <!-- ENDEREÇO -->
      <div class="form-row">
        <div class="form-group col-sm-4">
          <label>País:<span class="text-danger">*</span></label>
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.user.pais.$error }"
            placeholder="Digite o país"
            v-model="$v.user.pais.$model"
          />
          <span v-if="!$v.user.pais.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
        </div>
        <div class="form-group col-sm-6">
          <label>Complemento:</label>
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.user.complemento.$error }"
            placeholder="Complemento do endereço"
            v-model="$v.user.complemento.$model"
          />
        </div>
        <div class="form-group col-sm-2">
          <label>CEP:<span class="text-danger">*</span></label>
          <the-mask
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.user.cep.$error }"
            placeholder="00000-000"
            v-model="$v.user.cep.$model"
            :mask="'#####-###'"
            :masked="true"
          />
        </div>
        <span v-if="!$v.user.city_id.required" class="invalid-feedback">
          O campo é obrigatório
        </span>
      </div>
      <!-- BAIRRO, ESTADO E CIDADE -->
      <div class="form-row">
        <div class="form-group col-sm-6">
          <label>Unidade: <span class="text-danger">*</span></label>
          <select
            class="form-control"
            :class="{ 'is-invalid': $v.user.unidade_id.$error }"
            v-model="$v.user.unidade_id.$model"
          >
            <option value="">Selecione uma unidade</option>
            <option
              v-for="unidade in unidades"
              :key="unidade.id"
              :value="unidade.id"
              :title="unidade.descricao"
            >
              {{ unidade.descricao }}
            </option>
          </select>
          <span v-if="!$v.user.unidade_id.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
        </div>
        <div class="form-group col-sm-6">
          <label>Setor: <span class="text-danger">*</span></label>
          <select
            class="form-control"
            :class="{ 'is-invalid': $v.user.setor_id.$error }"
            v-model="$v.user.setor_id.$model"
          >
            <option value="">Selecione um setor</option>
            <option
              v-for="setor in setores"
              :key="setor.id"
              :value="setor.id"
              :title="setor.descricao"
            >
              {{ setor.descricao }}
            </option>
          </select>
          <span v-if="!$v.user.setor_id.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
        </div>
      </div>

      <!-- CARGO E DESCONTO -->
      <div class="form-row">
        <div class="form-group col-sm-4">
          <label>Cargo: <span class="text-danger">*</span></label>
          <select
            class="form-control"
            :class="{ 'is-invalid': $v.user.cargo_id.$error }"
            v-model="$v.user.cargo_id.$model"
          >
            <option value="">Selecione um cargo</option>
            <option
              v-for="cargo in cargos"
              :key="cargo.id"
              :value="cargo.id"
              :title="cargo.descricao"
            >
              {{ cargo.descricao }}
            </option>
          </select>
          <span v-if="!$v.user.cargo_id.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
        </div>
        <div class="form-group col-sm-4">
          <label>Perfil: <span class="text-danger">*</span></label>
          <select
            class="form-control"
            :class="{ 'is-invalid': $v.user.perfil_id.$error }"
            v-model="$v.user.perfil_id.$model"
          >
            <option value="">Selecione um perfil</option>
            <option
              v-for="perfil in perfis"
              :key="perfil.id"
              :value="perfil.id"
              :title="perfil.descricao"
            >
              {{ perfil.descricao }}
            </option>
          </select>
          <span v-if="!$v.user.perfil_id.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
        </div>
        <div class="form-group col-sm-4">
          <label>Desconto:</label>
          <input
            type="number"
            class="form-control"
            :class="{ 'is-invalid': $v.user.desconto.$error }"
            placeholder="% de desconto máx."
            v-model="$v.user.desconto.$model"
          />
        </div>
      </div>
      <div v-if="userId" class="form-row">
        <div class="form-group col-sm-6">
          <p>Status: <span class="text-danger">*</span></p>
          <div class="form-check form-check-inline">
            <input
              type="radio"
              class="form-check-input"
              id="statusInativo"
              value="0"
              v-model="user.status"
            />
            <label class="form-check-label" for="statusInativo">
              Inativo
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              type="radio"
              class="form-check-input"
              id="statusAtivo"
              value="1"
              v-model="user.status"
            />
            <label class="form-check-label" for="statusAtivo"> Ativo </label>
          </div>
        </div>
      </div>
      <hr />
      <div class="modal-footer-custom">
        <button
          type="button"
          class="btn btn-secondary"
          title="Cancelar"
          @click="$emit('closeModal', false)"
        >
          Cancelar
        </button>
        <button
          type="submit"
          class="btn btn-primary"
          :title="textModal"
          :disabled="$v.$invalid || disableButton"
        >
          <i
            v-if="disableButton"
            class="spinner-grow spinner-grow-custom text-wither"
          />
          <span v-else>{{ textModal }}</span>
        </button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators'
import { TheMask } from 'vue-the-mask'

export default {
  name: 'CreateEditUserModal',

  components: {
    TheMask,
  },

  props: {
    userId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      user: {
        name: '',
        email: '',
        phone: '',
        data_nascimento: '',
        cpf: '',
        rg: '',
        sexo: '',
        rg_orgao_expeditor: '',
        escolaridade: '',
        pis_pasep: '',
        titulo_eleitor: '',
        zona: '',
        secao: '',
        empresa_id: '',
        endereco: '',
        num_endereco: '',
        bairro: '',
        city_id: '',
        city_state_id: '',
        cep: '',
        pais: '',
        complemento: '',
        desconto: '',
        setor_id: '',
        unidade_id: '',
        cargo_id: '',
        perfil_id: '',
      },
      loadDataEditing: false,
      disableButton: false,
      states: null,
      cities: null,
      unidades: null,
      setores: null,
      cargos: null,
      perfis: null,
    }
  },

  computed: {
    textModal() {
      return this.userId ? 'Atualizar' : 'Cadastrar'
    },
  },

  watch: {
    'user.city_state_id': {
      handler(newValue, oldValue) {
        if (!this.loadDataEditing) {
          this.user.city_id = ''
        }
        if (newValue !== '') {
          this.getCities()
        }
      },
    },
    'user.unidade_id': {
      handler(newValue, oldValue) {
        if (!this.loadDataEditing) {
          this.user.setor_id = ''
        }
        if (newValue !== '') {
          this.getSetores()
        }
      },
    },
  },

  methods: {
    async getUser() {
      try {
        this.loadDataEditing = true
        this.user = await this.$store.dispatch('getUser', this.userId)
        this.$bvModal.show('modalCreateEditUser')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
        this.$emit('closeModal', false)
      }
    },

    async getStates() {
      try {
        this.states = await this.$store.dispatch('getStates')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getCities() {
      try {
        this.cities = await this.$store.dispatch(
          'getCities',
          this.user.city_state_id,
        )
        this.loadDataEditing = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getUnidades() {
      try {
        this.unidades = await this.$store.dispatch('getUnidades')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getSetores() {
      try {
        this.setores = await this.$store.dispatch(
          'getSetorByUnidade',
          this.user.unidade_id,
        )
        this.loadDataEditing = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getCargos() {
      try {
        this.cargos = await this.$store.dispatch('getCargos')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getPerfis() {
      try {
        this.perfis = await this.$store.dispatch('getPerfis')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async save() {
      if (!this.$v.$invalid) {
        this.disableButton = true
        try {
          let response = null
          if (this.userId) {
            response = await this.$store.dispatch('updateUser', this.user)
          } else {
            response = await this.$store.dispatch('saveUser', this.user)
          }
          this.disableButton = false
          this.$root.$bvToast.toast(response.message, {
            title: 'Sucesso!',
            variant: 'success',
            solid: true,
          })
          this.$emit('closeModal', true)
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$bvToast.toast(error.message, {
              title: 'Atenção!',
              variant: 'warning',
              solid: true,
            })
          } else {
            this.$root.$bvToast.toast(
              'Não foi possível se conectar ao servidor.',
              {
                title: 'Erro!',
                variant: 'danger',
                solid: true,
              },
            )
          }
        }
      }
    },
  },

  validations: {
    user: {
      name: {
        required,
        minLength: minLength(8),
      },
      email: {
        required,
        email,
      },
      phone: {
        minLength: minLength(14),
        maxLength: maxLength(15),
      },
      data_nascimento: {
        required,
      },
      cpf: {
        required,
      },
      rg: {
        required,
      },
      sexo: {
        required,
      },
      rg_orgao_expeditor: {},
      escolaridade: {},
      pis_pasep: {},
      titulo_eleitor: {},
      zona: {},
      secao: {},
      empresa_id: {},
      endereco: {
        required,
      },
      num_endereco: {
        required,
      },
      bairro: {
        required,
      },
      city_id: {
        required,
      },
      city_state_id: {
        required,
      },
      cep: {
        required,
      },
      pais: {
        required,
      },
      complemento: {},
      desconto: {},
      setor_id: {
        required,
      },
      unidade_id: {
        required,
      },
      cargo_id: {
        required,
      },
      perfil_id: {
        required,
      },
    },
  },

  mounted() {
    this.getStates()
    this.getUnidades()
    this.getCargos()
    this.getPerfis()
    if (this.userId) {
      this.getUser()
    } else {
      this.$bvModal.show('modalCreateEditUser')
    }
  },
}
</script>
