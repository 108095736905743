<template>
  <b-modal
    id="modalCreateEditTurma"
    size="xl"
    :title="textModal"
    title-class="font-18"
    hide-footer
    no-close-on-backdrop
    @hidden="$emit('closeModal', false)"
  >
    <form @submit.prevent="save">
      <div class="form-row">
        <div class="form-group col-sm-9">
          <label>Descricao: <span class="text-danger">*</span></label>
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.turma.descricao.$error }"
            placeholder="Descrição da turma"
            v-model="$v.turma.descricao.$model"
          />
          <span v-if="!$v.turma.descricao.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
          <span v-if="!$v.turma.descricao.minLength" class="invalid-feedback">
            A descrição deve possuir pelo menos 3 caracteres
          </span>
        </div>
        <div class="form-group col-sm-3">
          <label>Nº de vagas: </label>
          <input
            type="number"
            class="form-control"
            placeholder="Total de vagas da turma"
            :class="{ 'is-invalid': $v.turma.total_vagas.$error }"
            v-model="$v.turma.total_vagas.$model"
          />
          <span v-if="!$v.turma.total_vagas.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-6">
          <label>Responsável:</label>
          <select
            class="form-control"
            :class="{ 'is-invalid': $v.turma.user_id.$error }"
            v-model="$v.turma.user_id.$model"
          >
            <option value="">Selecione um responsável</option>
            <option
              v-for="user in users"
              :key="user.id"
              :value="user.id"
              :title="`${user.name}`"
            >
              {{ user.name }}
            </option>
          </select>
          <span v-if="!$v.turma.user_id.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
        </div>
        <div class="form-group col-sm-6">
          <label>Filial:</label>
          <select
            class="form-control"
            :class="{ 'is-invalid': $v.turma.unidade_id.$error }"
            v-model="$v.turma.unidade_id.$model"
          >
            <option value="">Selecione uma filial</option>
            <option
              v-for="unidade in unidades"
              :key="unidade.id"
              :value="unidade.id"
              :title="`${unidade.descricao}`"
            >
              {{ unidade.descricao }}
            </option>
          </select>
          <span v-if="!$v.turma.unidade_id.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
        </div>
      </div>
      <div class="form-row">
        <div v-if="turmaId" class="form-group col-sm-6">
          <p>Status: <span class="text-danger">*</span></p>
          <div class="form-check form-check-inline">
            <input
              type="radio"
              class="form-check-input"
              id="statusInativo"
              value="0"
              v-model="turma.status"
            />
            <label class="form-check-label" for="statusInativo">
              Inativo
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              type="radio"
              class="form-check-input"
              id="statusAtivo"
              value="1"
              v-model="turma.status"
            />
            <label class="form-check-label" for="statusAtivo"> Ativo </label>
          </div>
        </div>
      </div>
      <hr />
      <div class="modal-footer-custom">
        <button
          type="button"
          class="btn btn-secondary"
          title="Cancelar"
          @click="$emit('closeModal', false)"
        >
          Cancelar
        </button>
        <button
          type="submit"
          class="btn btn-primary"
          :title="textModal"
          :disabled="$v.$invalid || disableButton"
        >
          <i
            v-if="disableButton"
            class="spinner-grow spinner-grow-custom text-wither"
          />
          <span v-else>{{ textModal }}</span>
        </button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators'

export default {
  name: 'CreateEditTurmaModal',
  props: {
    turmaId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      turma: {
        descricao: '',
        total_vagas: '',
        user_id: '',
        unidade_id: '',
      },
      users: '',
      unidades: '',
      disableButton: false,
    }
  },

  computed: {
    textModal() {
      return this.turmaId ? 'Atualizar' : 'Cadastrar'
    },
  },

  methods: {
    async getTurma() {
      try {
        this.turma = await this.$store.dispatch('getTurma', this.turmaId)
        this.$bvModal.show('modalCreateEditTurma')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
        this.$emit('closeModal', false)
      }
    },

    async getUsers() {
      try {
        this.users = await this.$store.dispatch('getUsers')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getUnidades() {
      try {
        this.unidades = await this.$store.dispatch('getUnidades')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async save() {
      if (!this.$v.$invalid) {
        this.disableButton = true
        try {
          let response = null
          if (this.turmaId) {
            response = await this.$store.dispatch('updateTurma', {
              id: this.turmaId,
              turma: this.turma,
            })
          } else {
            response = await this.$store.dispatch('saveTurma', this.turma)
          }
          this.disableButton = false
          this.$root.$bvToast.toast(response.message, {
            title: 'Sucesso!',
            variant: 'success',
            solid: true,
          })
          this.$emit('closeModal', true)
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$bvToast.toast(error.message, {
              title: 'Atenção!',
              variant: 'warning',
              solid: true,
            })
          } else {
            this.$root.$bvToast.toast(
              'Não foi possível se conectar ao servidor.',
              {
                title: 'Erro!',
                variant: 'danger',
                solid: true,
              },
            )
          }
        }
      }
    },
  },

  validations: {
    turma: {
      descricao: {
        required,
        minLength: minLength(3),
      },
      total_vagas: {
        required,
      },
      user_id: {
        required,
      },
      unidade_id: {
        required,
      },
    },
  },

  mounted() {
    this.getUsers()
    this.getUnidades()
    if (this.turmaId) {
      this.getTurma()
    } else {
      this.$bvModal.show('modalCreateEditTurma')
    }
  },
}
</script>
