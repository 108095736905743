<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <CreateEditAlunoModal
      v-if="showCreateEditAlunoModal"
      :alunoId="alunoIdSelected"
      @closeModal="closeCreateEditAlunoModal"
    />
    <LoadingData v-if="loadData" />
    <div v-else class="card">
      <div
        class="card-header d-flex justify-content-between align-items-center bg-transparent border-bottom"
      >
        <h5 class="card-title mb-0">Alunos</h5>
        <div v-if="getModuleUserByCode('1.01.002')">
          <button
            type="button"
            class="btn btn-primary"
            title="Nova Aluno"
            @click="createEditAluno()"
          >
            <i class="ri-add-fill align-middle"></i>Novo Aluno
          </button>
        </div>
      </div>
      <div class="card-body">
        <ListAlunos :alunos="alunos" @editAluno="createEditAluno" />
      </div>
    </div>
  </Layout>
</template>

<script>
import { mapGetters } from 'vuex'
import Layout from '@/views/layouts/Layout.vue'
import PageHeader from '@/components/commons/PageHeader.vue'
import LoadingData from '@/components/commons/LoadingData.vue'
import ListAlunos from '@/components/escola/alunos/ListAlunos.vue'
import CreateEditAlunoModal from '@/components/escola/alunos/CreateEditAlunoModal.vue'

export default {
  name: 'Alunos',

  components: {
    Layout,
    PageHeader,
    CreateEditAlunoModal,
    LoadingData,
    ListAlunos,
  },

  data() {
    return {
      title: 'Alunos',
      items: [
        {
          text: 'Alunos',
          active: true,
        },
      ],
      loadData: true,
      showCreateEditAlunoModal: false,
      alunos: null,
      alunoIdSelected: null,
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  methods: {
    async getAlunos() {
      try {
        this.alunos = await this.$store.dispatch('getAlunos')
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    createEditAluno(alunoId) {
      this.alunoIdSelected = alunoId || null
      this.showCreateEditAlunoModal = true
    },

    closeCreateEditAlunoModal(loadListAlunos) {
      if (loadListAlunos) {
        this.getAlunos()
      }
      this.showCreateEditAlunoModal = false
    },
  },

  mounted() {
    this.getAlunos()
  },
}
</script>
